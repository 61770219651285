<template>
  <div v-if="gynlItem_" class="gybz-con flex">
    <div class="gybz-left flex-1 flex flex-align-center">
        <el-select :disabled="options.length<=0" :value="gynlItem_.name" placeholder="请选择工艺类型" @change="selectTag" value-key="id">
            <el-option v-for="(item,idx) in options" :key="idx" :label="item.name" :value="item.id">
            </el-option>
        </el-select>
    </div>
    <div class="gybz-right flex-1">
        <div v-if="gynlItem_ && gynlItem_.quota">
            <!-- <div style="width: 80px;font-size:18px;font-weight:bold;text-align:right;">{{curNl.name}}</div> -->
            <div v-for="(child,idx) in gynlItem_.quota" :key="idx" class="flex option">
                <div style="width:80px;line-height:40px;text-align:right;margin-right:16px;">{{child.name}}</div>
                <!-- 配置项类型，1单选，2多选，3文本，4整数，5小数，6百分比 -->
                <div v-if="child.type==1 || child.type==2">
                <el-select :multiple="child.type==2" v-model="child.value" placeholder="请选择">
                    <el-option v-for="item in child.options" :key="item.k" :label="item.v" :value="item.k"></el-option>
                </el-select>
                </div>
                <div v-if="child.type==3">
                <el-input v-model="child.value" style="width:200px" placeholder="请输入文本"></el-input>
                </div>
                <div v-if="child.type==4">
                <el-input v-model="child.value" type="number" style="min-width:200px" placeholder="请输入整数" min="0"></el-input>
                </div>
                <div v-if="child.type==5">
                <el-input v-model="child.value" type="number" style="min-width:200px" placeholder="请输入小数" min="0"></el-input>
                </div>
                <div v-if="child.type==6">
                <el-input v-model="child.value" type="number" style="min-width:200px" placeholder="请输入百分比" min="0"></el-input>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import { getFactoryMakeAbility } from "@/service/company";
export default {
  components: {},
  props: ["gynl","options",'formRef'],
  data() {
    return {
        allGynls:[],
        // selectedIdx:null,
        // gynlIdx: null,
        gynlItem:null,
        gynlItem_:null,
        // allSelectIdLength:0,
    };
  },
  watch: {
    "gynlItem_":{
      handler(newName, oldName) {
        this.syncDate();
      },
      deep:true
    }
  },
  async created() {
    // console.log(this.gybzIdx);
    // console.log(this.allSelectId);
    // if (this.allSelectId && this.allSelectId.length>0) {
    //   this.allSelectIdLength = this.allSelectId.length;
    // }
    // this.allGynls = (await getFactoryMakeAbility()) || [];
    // console.log("allGynls",this.allGynls);
    // if (this.allSelectId) {
    //   this.allGynls.forEach((e,idx)=>{
    //     this.allSelectId.forEach((n)=>{
    //       if (e.id==n) {
    //         this.allGynls.splice(idx,this.allSelectId.length);
    //         console.log(idx);
    //       }
    //     })
    //   })
    // }
  },
  mounted() {
    this.gynlItem = this.gynl;
    this.gynlItem_ = cloneDeep(this.gynl);
    // console.log(99999,this.gynl,this.gynlItem);
  },
  methods: {
    selectTag(id) {
        
        // this.gynlIdx = idx;
        // this.gynlItem = cloneDeep(this.options[idx]);
        this.gynlItem_ = cloneDeep(this.options.find(r=>{
          return r.id==id;
        }));
        console.log(777,this.gynlItem_);
        // this.syncDate();
    },
    syncDate() {
        let cloneV = cloneDeep(this.gynlItem_);
        delete cloneV.selected;
        if (cloneV.quota && cloneV.quota.length > 0) {
            for (let quota_ of cloneV.quota) {
                delete quota_.options;
                delete quota_.type;
            }
        }
        console.log(123,cloneV);
        this.$emit('update:gynl', cloneV)
        // this.gynlItem = cloneV;
        // this.gynlItem=cloneV;
        // this.gynl=cloneV;
        // this.$emit('change', cloneV);
    }
  }
};
</script>
<style scoped lang="less">
.gybz-con {
    width: 800px;
    min-height: 140px;
    background: #E6E6E6;
    padding: 10px 0;
    margin-bottom: 30px;
    .gybz-left {
        padding-left: 50px;
        border-right: 1px solid #CCCCCC;
    }
    .gybz-right {
        padding: 10px;
        font-size: 14px;
        color: #808080;
        min-width: 400px;
        .option:first-child {
            margin-bottom: 10px;
        }
    }
}
</style>