import { render, staticRenderFns } from "./Gynl.vue?vue&type=template&id=243d9a34&scoped=true&"
import script from "./Gynl.vue?vue&type=script&lang=js&"
export * from "./Gynl.vue?vue&type=script&lang=js&"
import style0 from "./Gynl.vue?vue&type=style&index=0&id=243d9a34&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "243d9a34",
  null
  
)

export default component.exports