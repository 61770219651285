<template>
  <div class="zt-block">
    <div class="zt-block-head">工艺要求</div>
    <div class="zt-block-content">
      <div v-for="(_gynl,_index) of gynls_" :key="_index" style="position: relative;">
        <!-- <template v-if="gynlOptions.length>0"> -->
          <el-button type="text" icon="el-icon-delete" class="del-btn" @click="del(_index)">删除</el-button>
          <Gynl :gynl.sync="gynls_[_index]" :ref="'gynl_'+_index" :options="gynlOptions"></Gynl>
        <!-- </template> -->
      </div>
      <el-button v-if="gynlOptions.length>0" type="text" icon="el-icon-circle-plus-outline"  @click="addCase">添加工艺要求标准</el-button>
    </div>
  </div>
</template>
<script>
import Gynl from "./Gynl";
import { getFactoryMakeAbility } from "@/service/company";
export default {
  components: {
    Gynl
  },
  model: {
    prop: "gynls",
    event: "change"
  },
  props: ["gynls"],
  data() {
    return {
      gynls_: this.gynls,
      allGynls:[],
    };
  },
  watch: {
    "gynls":{
      handler(newName, oldName) {
        
        let tmp=newName;
        this.syncSelected(tmp);
        this.gynls_=tmp;
        console.log("watch gynls",this.gynls_);
      },
      deep:true
    }
  },
  async created() {
    this.allGynls = (await getFactoryMakeAbility()) || [];
    
  },
  mounted(){
    console.log("Gynls mounted",this.gynls);
  },
  computed:{
    gynlOptions:function(){
      let rst=[]; 
      if(this.gynls_) this.allGynls.forEach(r=>{
        console.log(33333,r,this.gynls_);
        let exist = this.gynls_.find(rr=>{
          return rr.id==r.id;
        });
        if(!exist) rst.push(r);
      });
      return rst;
    }
  },
  methods: {
    syncSelected(src) {
      if (this.allGynls && this.allGynls.length > 0) {
        for (let Gynl of this.allGynls) {
          // 已选中的能力
          let exist = src.find(r => {
            return r.id == Gynl.id;
          });
          
          if (exist) {
            
            // Gynl.selected = true;
            // Gynl.primary = exist.primary;
            if (Gynl.quota && Gynl.quota.length > 0) {
              for (let quota_ of Gynl.quota) {
                // 被设置过值得指标
                let existQuota = exist.quota.find(r => {
                  return r.id == quota_.id;
                });
                if (existQuota) {
                  console.log("syncSelected",existQuota,quota_);
                  Object.assign(existQuota,quota_);
                  // quota_.value = existQuota.value;
                }
              }
            }
          }
        }
      }
      // console.log(this.allGynls,this.ability_make);
    },
    addCase() {
      if (this.gynls_ && this.gynls_.length > 0) {
        this.valid()
          .then(() => {
            this.gynls_.push({});
            this.$emit("change", this.gynls_);
          })
          .catch(err => {});
      } else {
        this.gynls_ = [{}];
        this.$emit("change", this.gynls_);
      }
    },
    async valid() {
      if (this.gynls_ && this.gynls_.length > 0) {
        for (let i = 0; i < this.gynls_.length; i++) {
          let refname = "gynl_" + i;
          // await this.$refs[refname][0].validForm();
        }
      }else{
        throw new Error("至少填写一个工艺能力标准");
      }
    },
    del(_index){
      this.gynls_.splice(_index,1);
    }
  }
};
</script>
<style scoped>
.del-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}
</style>